<template>
	<div>
		<top :windowWidth="windowWidth" :windowHeight="windowHeight"></top>
		<right v-if="windowWidth>=1200"></right>
		<banner :windowWidth="windowWidth" :windowHeight="windowHeight"></banner>
		<solution-provider :windowWidth="windowWidth" :windowHeight="windowHeight"></solution-provider>
		<service-area :windowWidth="windowWidth" :windowHeight="windowHeight"></service-area>
		<electronic-design :windowWidth="windowWidth" :windowHeight="windowHeight"></electronic-design>
		<!-- <design-process></design-process> -->
		<bottom v-if="windowWidth>=1200"></bottom>
	</div>
</template>

<script>
	import top from '@/components/top/index.vue';
	import right from '@/components/right/index.vue';
	import banner from './components/schemeDesign/banner.vue';
	import solutionProvider from './components/schemeDesign/solutionProvider.vue';
	import serviceArea from './components/schemeDesign/serviceArea.vue';
	import electronicDesign from './components/schemeDesign/electronicDesign.vue';
	import designProcess from './components/schemeDesign/designProcess.vue';
	import bottom from '@/components/bottom/index.vue';
	export default {
		components:{
			top,
			banner,
			solutionProvider,
			serviceArea,
			electronicDesign,
			designProcess,
			bottom,
			right
		},
		data() {
			return {
				// 浏览器窗口宽度高度
				windowWidth: 0,
				windowHeight: 0,
			}
		},
		
		created() {
			
		},
		
		mounted() {
			let that = this;
			that.windowWidth = document.body.clientWidth;
			that.windowHeight = document.body.clientHeight;
			window.onresize = () => {
				return (() => {
					this.windowWidth = document.body.clientWidth;
					this.windowHeight = document.body.clientHeight;
				})();
			};
		},
		
		watch:{
			
		},
		
		computed:{
			
		},
		
		methods:{
			
		}
	}
</script>

<style lang="less" scoped>
	
</style>