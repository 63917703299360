<template>
	<div>
		<div class="solutionProvider" v-if="windowWidth>=1200">
			<div class="sp_titleDiv">
				<div class="sp_td_title">{{$t('solution.schemeDesign.solutionProvider.title')}}</div>
				<div class="sp_td_text">{{$t('solution.schemeDesign.solutionProvider.text')}}</div>
			</div>
			
			<dl class="sp_solveBox">
				<dd class="sp_sb_listDiv" v-for="(item,index) in $t('solution.schemeDesign.solutionProvider.list')" :key="index" :class="item.img1">
					<img class="sp_sb_ld_img" :src="item.img2" />
					<div class="sp_sb_ld_name">{{item.name}}</div>
					<div class="sp_sb_ld_title">{{item.title}}</div>
					<div class="sp_sb_ld_textListDiv">
						<div class="sp_sb_ld_tld_text" v-for="(item2,index2) in item.textList" :key="index2">
							{{item2}}
						</div>
					</div>
				</dd>
			</dl>
		</div>
		
		<div class="solutionProvider2" v-if="windowWidth<=1199">
			<div class="sp_titleDiv">
				<div class="sp_td_title">{{$t('solution.schemeDesign.solutionProvider.title')}}</div>
				<div class="sp_td_text">{{$t('solution.schemeDesign.solutionProvider.text')}}</div>
			</div>
			
			<dl class="sp_solveBox">
				<dd class="sp_sb_listDiv" v-for="(item,index) in $t('solution.schemeDesign.solutionProvider.list')" :key="index" :class="item.img1">
					<img class="sp_sb_ld_img" :src="item.img2" />
					<div class="sp_sb_ld_name">{{item.name}}</div>
					<div class="sp_sb_ld_title">{{item.title}}</div>
					<div class="sp_sb_ld_textListDiv">
						<div class="sp_sb_ld_tld_text" v-for="(item2,index2) in item.textList" :key="index2">
							{{item2}}
						</div>
					</div>
				</dd>
			</dl>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			//宽
			windowWidth: {
				type: Number,
				default: 0
			},
			//高
			windowHeight: {
				type: Number,
				default: 0
			},
		},
		data(){
			return{
				
			}
		},
		
		created() {
			
		},
		
		mounted() {
			
		},
		
		watch:{
			
		},
		
		computed:{
			
		},
		
		methods:{
			
		}
	}
</script>

<style lang="less" scoped>
	
	@media only screen and (min-width:1920px) {
		.solutionProvider{
			padding: 40px 200px;
			
			.sp_titleDiv{
				text-align: center;
				
				.sp_td_title{
					font-size: 34px;
					color: #333333;
				}
				
				.sp_td_text{
					font-size: 18px;
					color: #999999;
					margin-top: 8px;
				}
			}
			
			.sp_solveBox{
				position: relative;
				margin-top: 83px;
				width: 100%;
				display: flex;
				
				.sp_sb_listDiv{
					margin-left: 1%;
					float: left;
					background-color: #f4f5f7;
					position: relative;
					z-index: 2;
					padding: 0 40px;
					box-sizing: border-box;
					transition: 0.3s;
					width: 24%;
					height: 400px;
					
					.sp_sb_ld_img{
						position: absolute;
						top: -34px;
						left: 40px;
						z-index: 1;
					}
					
					.sp_sb_ld_name{
						font-size: 20px;
						font-weight: bold;
						color: #f13c36;
						margin-top: 2px;
					}
					
					.sp_sb_ld_title{
						margin-top: 36px;
						font-size: 20px;
						color: #333333;
						font-weight: bold;
					}
					
					.sp_sb_ld_textListDiv{
						padding-top: 18px;
						
						.sp_sb_ld_tld_text{
							font-size: 16px;
							line-height: 32px;
							color: #666666;
						}
					}
				}
				
				.sp_sb_listDiv:hover{
					transform: translateY(-5px);
				}
			}
		}
		
		.img1{
			background: url(../../../../assets/img/solve-img1.png) no-repeat 80% 94% #f4f5f7;
		}
		
		.img2{
			background: url(../../../../assets/img/solve-img2.png) no-repeat 80% 94% #f4f5f7;
		}
		
		.img3{
			background: url(../../../../assets/img/solve-img3.png) no-repeat 80% 94% #f4f5f7;
		}
		
		.img4{
			background: url(../../../../assets/img/solve-img4.png) no-repeat 80% 94% #f4f5f7;
		}
	}
	
	@media only screen and (max-width:1920px) {
		.solutionProvider{
			padding: 40px 200px;
			
			.sp_titleDiv{
				text-align: center;
				
				.sp_td_title{
					font-size: 34px;
					color: #333333;
				}
				
				.sp_td_text{
					font-size: 18px;
					color: #999999;
					margin-top: 8px;
				}
			}
			
			.sp_solveBox{
				position: relative;
				margin-top: 83px;
				width: 100%;
				display: flex;
				
				.sp_sb_listDiv{
					margin-left: 1%;
					float: left;
					background-color: #f4f5f7;
					position: relative;
					z-index: 2;
					padding: 0 40px;
					box-sizing: border-box;
					transition: 0.3s;
					width: 24%;
					height: 400px;
					
					.sp_sb_ld_img{
						position: absolute;
						top: -34px;
						left: 40px;
						z-index: 1;
					}
					
					.sp_sb_ld_name{
						font-size: 20px;
						font-weight: bold;
						color: #f13c36;
						margin-top: 2px;
					}
					
					.sp_sb_ld_title{
						margin-top: 36px;
						font-size: 20px;
						color: #333333;
						font-weight: bold;
					}
					
					.sp_sb_ld_textListDiv{
						padding-top: 18px;
						
						.sp_sb_ld_tld_text{
							font-size: 16px;
							line-height: 32px;
							color: #666666;
						}
					}
				}
				
				.sp_sb_listDiv:hover{
					transform: translateY(-5px);
				}
			}
		}
		
		.img1{
			background: url(../../../../assets/img/solve-img1.png) no-repeat 80% 94% #f4f5f7;
		}
		
		.img2{
			background: url(../../../../assets/img/solve-img2.png) no-repeat 80% 94% #f4f5f7;
		}
		
		.img3{
			background: url(../../../../assets/img/solve-img3.png) no-repeat 80% 94% #f4f5f7;
		}
		
		.img4{
			background: url(../../../../assets/img/solve-img4.png) no-repeat 80% 94% #f4f5f7;
		}
	}
	
	@media only screen and (max-width:1919px) and (min-width:1440px) {
		.solutionProvider{
			padding: 40px 140px;
			
			.sp_titleDiv{
				text-align: center;
				
				.sp_td_title{
					font-size: 34px;
					color: #333333;
				}
				
				.sp_td_text{
					font-size: 18px;
					color: #999999;
					margin-top: 8px;
				}
			}
			
			.sp_solveBox{
				position: relative;
				margin-top: 83px;
				width: 100%;
				display: flex;
				
				.sp_sb_listDiv{
					margin-left: 1%;
					float: left;
					background-color: #f4f5f7;
					position: relative;
					z-index: 2;
					padding: 0 40px;
					box-sizing: border-box;
					transition: 0.3s;
					width: 24%;
					height: 400px;
					
					.sp_sb_ld_img{
						position: absolute;
						top: -34px;
						left: 40px;
						z-index: 1;
					}
					
					.sp_sb_ld_name{
						font-size: 20px;
						font-weight: bold;
						color: #f13c36;
						margin-top: 2px;
					}
					
					.sp_sb_ld_title{
						margin-top: 36px;
						font-size: 20px;
						color: #333333;
						font-weight: bold;
					}
					
					.sp_sb_ld_textListDiv{
						padding-top: 18px;
						
						.sp_sb_ld_tld_text{
							font-size: 16px;
							line-height: 32px;
							color: #666666;
						}
					}
				}
				
				.sp_sb_listDiv:hover{
					transform: translateY(-5px);
				}
			}
		}
		
		.img1{
			background: url(../../../../assets/img/solve-img1.png) no-repeat 80% 94% #f4f5f7;
		}
		
		.img2{
			background: url(../../../../assets/img/solve-img2.png) no-repeat 80% 94% #f4f5f7;
		}
		
		.img3{
			background: url(../../../../assets/img/solve-img3.png) no-repeat 80% 94% #f4f5f7;
		}
		
		.img4{
			background: url(../../../../assets/img/solve-img4.png) no-repeat 80% 94% #f4f5f7;
		}
	}
	
	@media only screen and (max-width:1439px) and (min-width:1200px) {
		.solutionProvider{
			padding: 40px 30px;
			
			.sp_titleDiv{
				text-align: center;
				
				.sp_td_title{
					font-size: 34px;
					color: #333333;
				}
				
				.sp_td_text{
					font-size: 18px;
					color: #999999;
					margin-top: 8px;
				}
			}
			
			.sp_solveBox{
				position: relative;
				margin-top: 83px;
				width: 100%;
				display: flex;
				
				.sp_sb_listDiv{
					margin-left: 1%;
					float: left;
					background-color: #f4f5f7;
					position: relative;
					z-index: 2;
					padding: 0 40px;
					box-sizing: border-box;
					transition: 0.3s;
					width: 24%;
					height: 400px;
					
					.sp_sb_ld_img{
						position: absolute;
						top: -34px;
						left: 40px;
						z-index: 1;
					}
					
					.sp_sb_ld_name{
						font-size: 20px;
						font-weight: bold;
						color: #f13c36;
						margin-top: 2px;
					}
					
					.sp_sb_ld_title{
						margin-top: 36px;
						font-size: 20px;
						color: #333333;
						font-weight: bold;
					}
					
					.sp_sb_ld_textListDiv{
						padding-top: 18px;
						
						.sp_sb_ld_tld_text{
							font-size: 16px;
							line-height: 32px;
							color: #666666;
						}
					}
				}
				
				.sp_sb_listDiv:hover{
					transform: translateY(-5px);
				}
			}
		}
		
		.img1{
			background: url(../../../../assets/img/solve-img1.png) no-repeat 80% 94% #f4f5f7;
		}
		
		.img2{
			background: url(../../../../assets/img/solve-img2.png) no-repeat 80% 94% #f4f5f7;
		}
		
		.img3{
			background: url(../../../../assets/img/solve-img3.png) no-repeat 80% 94% #f4f5f7;
		}
		
		.img4{
			background: url(../../../../assets/img/solve-img4.png) no-repeat 80% 94% #f4f5f7;
		}
	}
	
	@media only screen and (max-width:1199px) and (min-width:768px) {
		.solutionProvider2{
			padding: 40px 30px;
			
			.sp_titleDiv{
				text-align: center;
				
				.sp_td_title{
					font-size: 21px;
					color: #333333;
				}
				
				.sp_td_text{
					font-size: 16px;
					color: #999999;
					margin-top: 8px;
				}
			}
			
			.sp_solveBox{
				position: relative;
				margin-top: 83px;
				width: 100%;
				display: flex;
				flex-wrap: wrap;
				
				.sp_sb_listDiv{
					margin-left: 1%;
					float: left;
					background-color: #f4f5f7;
					position: relative;
					z-index: 2;
					padding: 0 40px;
					box-sizing: border-box;
					transition: 0.3s;
					width: 48%;
					height: 400px;
					
					.sp_sb_ld_img{
						position: absolute;
						top: -34px;
						left: 40px;
						z-index: 1;
					}
					
					.sp_sb_ld_name{
						font-size: 20px;
						font-weight: bold;
						color: #f13c36;
						margin-top: 2px;
					}
					
					.sp_sb_ld_title{
						margin-top: 36px;
						font-size: 20px;
						color: #333333;
						font-weight: bold;
					}
					
					.sp_sb_ld_textListDiv{
						padding-top: 18px;
						
						.sp_sb_ld_tld_text{
							font-size: 16px;
							line-height: 32px;
							color: #666666;
						}
					}
				}
				
				.sp_sb_listDiv:hover{
					transform: translateY(-5px);
				}
			}
		}
		
		.img1{
			background: url(../../../../assets/img/solve-img1.png) no-repeat 80% 94% #f4f5f7;
		}
		
		.img2{
			background: url(../../../../assets/img/solve-img2.png) no-repeat 80% 94% #f4f5f7;
		}
		
		.img3{
			background: url(../../../../assets/img/solve-img3.png) no-repeat 80% 94% #f4f5f7;
		}
		
		.img4{
			background: url(../../../../assets/img/solve-img4.png) no-repeat 80% 94% #f4f5f7;
		}
	}
	
	@media only screen and (max-width:767px) {
		.solutionProvider2{
			padding: 40px 30px;
			
			.sp_titleDiv{
				text-align: center;
				
				.sp_td_title{
					font-size: 21px;
					color: #333333;
				}
				
				.sp_td_text{
					font-size: 16px;
					color: #999999;
					margin-top: 8px;
				}
			}
			
			.sp_solveBox{
				position: relative;
				margin-top: 83px;
				width: 100%;
				display: flex;
				flex-wrap: wrap;
				
				.sp_sb_listDiv{
					float: left;
					background-color: #f4f5f7;
					position: relative;
					z-index: 2;
					padding: 0 40px;
					box-sizing: border-box;
					transition: 0.3s;
					width: 100%;
					height: 400px;
					
					.sp_sb_ld_img{
						position: absolute;
						top: -34px;
						left: 40px;
						z-index: 1;
					}
					
					.sp_sb_ld_name{
						font-size: 20px;
						font-weight: bold;
						color: #f13c36;
						margin-top: 2px;
					}
					
					.sp_sb_ld_title{
						margin-top: 36px;
						font-size: 20px;
						color: #333333;
						font-weight: bold;
					}
					
					.sp_sb_ld_textListDiv{
						padding-top: 18px;
						
						.sp_sb_ld_tld_text{
							font-size: 16px;
							line-height: 32px;
							color: #666666;
						}
					}
				}
				
				.sp_sb_listDiv:hover{
					transform: translateY(-5px);
				}
			}
		}
		
		.img1{
			background: url(../../../../assets/img/solve-img1.png) no-repeat 80% 94% #f4f5f7;
		}
		
		.img2{
			background: url(../../../../assets/img/solve-img2.png) no-repeat 80% 94% #f4f5f7;
		}
		
		.img3{
			background: url(../../../../assets/img/solve-img3.png) no-repeat 80% 94% #f4f5f7;
		}
		
		.img4{
			background: url(../../../../assets/img/solve-img4.png) no-repeat 80% 94% #f4f5f7;
		}
	}
	
</style>