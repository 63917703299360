<template>
	<div>
		<div class="designProcess">
			<div class="dp_title">设计流程</div>

			<img class="dp_img" src="../../../../assets/img/the-design-process.png" />

			<div class="dp_jump">
				立即咨询下单
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {

			}
		},

		created() {

		},

		mounted() {

		},

		watch: {

		},

		computed: {

		},

		methods: {

		}
	}
</script>

<style lang="less" scoped>
	.designProcess {
		background: url(../../../../assets/img/the-design-bg.jpg) no-repeat center #4a4f63;
		padding: 0;
		margin-top: 20px;
		padding: 40px 200px;
		overflow: hidden;

		.dp_title {
			font-size: 34px;
			color: #fff;
			margin-top: 50px;
			text-align: center;
		}

		.dp_img {
			width: 100%;
			padding-top: 40px;
		}

		.dp_jump {
			background: url(../../../../assets/img/consult-btn.png) no-repeat center;
			width: 322px;
			height: 104px;
			display: block;
			margin: 70px auto 0;
			line-height: 80px;
			font-size: 24px;
			color: #fff;
			text-align: center;
		}
	}
</style>
