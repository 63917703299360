<template>
	<div>
		<div class="electronicDesign" v-if="windowWidth>=1200">
			<div class="ed_titleDiv">
				<div class="ed_td_title">{{$t('solution.schemeDesign.electronicDesign.title')}}</div>
				<div class="ed_td_text">{{$t('solution.schemeDesign.electronicDesign.text')}}</div>
			</div>

			<div class="ed_simulationBoxed">
				<div class="ed_sb_simulation">
					<img class="ed_sb_sl_img" :src="img1" alt />
					<div class="ed_sb_sl_title">{{$t('solution.schemeDesign.electronicDesign.icon1.title')}}</div>
					<div class="ed_sb_sl_text">
						{{$t('solution.schemeDesign.electronicDesign.icon1.text1')}}
						<br />
						{{$t('solution.schemeDesign.electronicDesign.icon1.text2')}}
					</div>
				</div>

				<div class="ed_sb_simulation">
					<img class="ed_sb_sl_img" :src="img2" alt />
					<div class="ed_sb_sl_title">{{$t('solution.schemeDesign.electronicDesign.icon2.title')}}</div>
					<div class="ed_sb_sl_text">
						{{$t('solution.schemeDesign.electronicDesign.icon2.text1')}}
						<br />
						{{$t('solution.schemeDesign.electronicDesign.icon2.text2')}}
					</div>
				</div>

				<div class="ed_sb_simulation">
					<img class="ed_sb_sl_img" :src="img3" alt />
					<div class="ed_sb_sl_title">{{$t('solution.schemeDesign.electronicDesign.icon3.title')}}</div>
					<div class="ed_sb_sl_text">
						{{$t('solution.schemeDesign.electronicDesign.icon3.text1')}}
						<br />
						{{$t('solution.schemeDesign.electronicDesign.icon3.text2')}}
					</div>
				</div>

				<div class="ed_sb_simulation">
					<img class="ed_sb_sl_img" :src="img4" alt />
					<div class="ed_sb_sl_title">{{$t('solution.schemeDesign.electronicDesign.icon4.title')}}</div>
					<div class="ed_sb_sl_text">
						{{$t('solution.schemeDesign.electronicDesign.icon4.text1')}}
						<br />
						{{$t('solution.schemeDesign.electronicDesign.icon4.text2')}}
					</div>
				</div>
			</div>

			<div class="ed_advantageBox">
				<div class="ed_ab_advantageLeft">
					<div class="ed_ab_al_title">
						{{$t('solution.schemeDesign.electronicDesign.leftContent.title')}}
					</div>
					<div class="ed_ab_al_textDiv">
						<img class="ed_ab_al_td_img" :src="icon1" />
						<div class="ed_ab_al_td_title">{{$t('solution.schemeDesign.electronicDesign.leftContent.text1')}}</div>
						<div class="ed_ab_al_td_text">{{$t('solution.schemeDesign.electronicDesign.leftContent.text2')}}</div>
					</div>

					<div class="ed_ab_al_textDiv">
						<img class="ed_ab_al_td_img" :src="icon2" />
						<div class="ed_ab_al_td_title">{{$t('solution.schemeDesign.electronicDesign.leftContent.text3')}}</div>
						<div class="ed_ab_al_td_text">{{$t('solution.schemeDesign.electronicDesign.leftContent.text4')}}</div>
					</div>

					<div class="ed_ab_al_textDiv">
						<img class="ed_ab_al_td_img" :src="icon3" />
						<div class="ed_ab_al_td_title">{{$t('solution.schemeDesign.electronicDesign.leftContent.text5')}}</div>
						<div class="ed_ab_al_td_text">{{$t('solution.schemeDesign.electronicDesign.leftContent.text6')}}</div>
					</div>

					<div class="ed_ab_al_textDiv">
						<img class="ed_ab_al_td_img" :src="icon4" />
						<div class="ed_ab_al_td_title">{{$t('solution.schemeDesign.electronicDesign.leftContent.text7')}}</div>
						<div class="ed_ab_al_td_text">{{$t('solution.schemeDesign.electronicDesign.leftContent.text8')}}</div>
					</div>
				</div>

				<div class="ed_ab_advantageRight">
					<div class="ed_ab_ar_title">
						{{$t('solution.schemeDesign.electronicDesign.rightContent.title')}}
					</div>
					<div style="width: 100%;display: flex;flex-wrap: wrap;justify-content: center;">
						<div class="ed_ab_ar_product">
							<div class="ed_ab_ar_pd_title">{{$t('solution.schemeDesign.electronicDesign.rightContent.text1')}}</div>
							<div class="ed_ab_ar_pd_text">{{$t('solution.schemeDesign.electronicDesign.rightContent.text2')}}</div>
						</div>
						
						<div class="ed_ab_ar_product2">
							<div class="ed_ab_ar_pd_title">{{$t('solution.schemeDesign.electronicDesign.rightContent.text3')}}</div>
							<div class="ed_ab_ar_pd_text">{{$t('solution.schemeDesign.electronicDesign.rightContent.text4')}}</div>
						</div>
						
						<div class="ed_ab_ar_product3">
							<div class="ed_ab_ar_pd_title">{{$t('solution.schemeDesign.electronicDesign.rightContent.text5')}}</div>
							<div class="ed_ab_ar_pd_text">{{$t('solution.schemeDesign.electronicDesign.rightContent.text6')}}</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		
		<div class="electronicDesign2" v-if="windowWidth<=1199">
			<div class="ed_titleDiv">
				<div class="ed_td_title">{{$t('solution.schemeDesign.electronicDesign.title')}}</div>
				<div class="ed_td_text">{{$t('solution.schemeDesign.electronicDesign.text')}}</div>
			</div>
		
			<div class="ed_simulationBoxed">
				<div class="ed_sb_simulation">
					<img class="ed_sb_sl_img" :src="img1" alt />
					<div class="ed_sb_sl_title">{{$t('solution.schemeDesign.electronicDesign.icon1.title')}}</div>
					<div class="ed_sb_sl_text">
						{{$t('solution.schemeDesign.electronicDesign.icon1.text1')}}
						<br />
						{{$t('solution.schemeDesign.electronicDesign.icon1.text2')}}
					</div>
				</div>
		
				<div class="ed_sb_simulation">
					<img class="ed_sb_sl_img" :src="img2" alt />
					<div class="ed_sb_sl_title">{{$t('solution.schemeDesign.electronicDesign.icon2.title')}}</div>
					<div class="ed_sb_sl_text">
						{{$t('solution.schemeDesign.electronicDesign.icon2.text1')}}
						<br />
						{{$t('solution.schemeDesign.electronicDesign.icon2.text2')}}
					</div>
				</div>
		
				<div class="ed_sb_simulation">
					<img class="ed_sb_sl_img" :src="img3" alt />
					<div class="ed_sb_sl_title">{{$t('solution.schemeDesign.electronicDesign.icon3.title')}}</div>
					<div class="ed_sb_sl_text">
						{{$t('solution.schemeDesign.electronicDesign.icon3.text1')}}
						<br />
						{{$t('solution.schemeDesign.electronicDesign.icon3.text2')}}
					</div>
				</div>
		
				<div class="ed_sb_simulation">
					<img class="ed_sb_sl_img" :src="img4" alt />
					<div class="ed_sb_sl_title">{{$t('solution.schemeDesign.electronicDesign.icon4.title')}}</div>
					<div class="ed_sb_sl_text">
						{{$t('solution.schemeDesign.electronicDesign.icon4.text1')}}
						<br />
						{{$t('solution.schemeDesign.electronicDesign.icon4.text2')}}
					</div>
				</div>
			</div>
		
			<div class="ed_advantageBox">
				<div class="ed_ab_advantageLeft">
					<div class="ed_ab_al_title">
						{{$t('solution.schemeDesign.electronicDesign.leftContent.title')}}
					</div>
					<div class="ed_ab_al_textDiv">
						<img class="ed_ab_al_td_img" :src="icon1" />
						<div class="ed_ab_al_td_title">{{$t('solution.schemeDesign.electronicDesign.leftContent.text1')}}</div>
						<div class="ed_ab_al_td_text">{{$t('solution.schemeDesign.electronicDesign.leftContent.text2')}}</div>
					</div>
		
					<div class="ed_ab_al_textDiv">
						<img class="ed_ab_al_td_img" :src="icon2" />
						<div class="ed_ab_al_td_title">{{$t('solution.schemeDesign.electronicDesign.leftContent.text3')}}</div>
						<div class="ed_ab_al_td_text">{{$t('solution.schemeDesign.electronicDesign.leftContent.text4')}}</div>
					</div>
		
					<div class="ed_ab_al_textDiv">
						<img class="ed_ab_al_td_img" :src="icon3" />
						<div class="ed_ab_al_td_title">{{$t('solution.schemeDesign.electronicDesign.leftContent.text5')}}</div>
						<div class="ed_ab_al_td_text">{{$t('solution.schemeDesign.electronicDesign.leftContent.text6')}}</div>
					</div>
		
					<div class="ed_ab_al_textDiv">
						<img class="ed_ab_al_td_img" :src="icon4" />
						<div class="ed_ab_al_td_title">{{$t('solution.schemeDesign.electronicDesign.leftContent.text7')}}</div>
						<div class="ed_ab_al_td_text">{{$t('solution.schemeDesign.electronicDesign.leftContent.text8')}}</div>
					</div>
				</div>
		
				<div class="ed_ab_advantageRight">
					<div class="ed_ab_ar_title">
						{{$t('solution.schemeDesign.electronicDesign.rightContent.title')}}
					</div>
					<div style="width: 100%;display: flex;flex-wrap: wrap;justify-content: center;">
						<div class="ed_ab_ar_product">
							<div class="ed_ab_ar_pd_title">{{$t('solution.schemeDesign.electronicDesign.rightContent.text1')}}</div>
							<div class="ed_ab_ar_pd_text">{{$t('solution.schemeDesign.electronicDesign.rightContent.text2')}}</div>
						</div>
						
						<div class="ed_ab_ar_product2">
							<div class="ed_ab_ar_pd_title">{{$t('solution.schemeDesign.electronicDesign.rightContent.text3')}}</div>
							<div class="ed_ab_ar_pd_text">{{$t('solution.schemeDesign.electronicDesign.rightContent.text4')}}</div>
						</div>
						
						<div class="ed_ab_ar_product3">
							<div class="ed_ab_ar_pd_title">{{$t('solution.schemeDesign.electronicDesign.rightContent.text5')}}</div>
							<div class="ed_ab_ar_pd_text">{{$t('solution.schemeDesign.electronicDesign.rightContent.text6')}}</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			//宽
			windowWidth: {
				type: Number,
				default: 0
			},
			//高
			windowHeight: {
				type: Number,
				default: 0
			},
		},
		data() {
			return {
				img1:process.env.VUE_APP_OSS_URL + '/solution/simulation1.png',
				img2:process.env.VUE_APP_OSS_URL + '/solution/simulation2.png',
				img3:process.env.VUE_APP_OSS_URL + '/solution/simulation3.png',
				img4:process.env.VUE_APP_OSS_URL + '/solution/simulation4.png',
				icon1:process.env.VUE_APP_OSS_URL + '/solution/adv-icon1.png',
				icon2:process.env.VUE_APP_OSS_URL + '/solution/adv-icon2.png',
				icon3:process.env.VUE_APP_OSS_URL + '/solution/adv-icon3.png',
				icon4:process.env.VUE_APP_OSS_URL + '/solution/adv-icon4.png',
			}
		},

		created() {

		},

		mounted() {

		},

		watch: {

		},

		computed: {

		},

		methods: {

		}
	}
</script>

<style lang="less" scoped>
	
	@media only screen and (min-width:1920px) {
		.electronicDesign {
			padding: 40px 200px;
		
			.ed_titleDiv {
				text-align: center;
		
				.ed_td_title {
					font-size: 34px;
					color: #333333;
					text-align: center;
				}
		
				.ed_td_text {
					font-size: 18px;
					color: #999999;
					text-align: center;
					margin-top: 8px;
				}
			}
		
			.ed_simulationBoxed {
				margin: 50px auto 0;
				display: flex;
		
				.ed_sb_simulation {
					width: 25%;
					text-align: center;
					
					.ed_sb_sl_img{
						border: none;
						vertical-align: middle;
						width: 111px;
						height: 111px;
					}
		
					.ed_sb_sl_title {
						margin-top: 16px;
						font-size: 18px;
						font-weight: bold;
						color: #333333;
					}
		
					.ed_sb_sl_text {
						margin-top: 24px;
						font-size: 16px;
						color: #757575;
					}
				}
			}
		
			.ed_advantageBox {
				margin-top: 50px;
				border: solid 1px #e2e2e2;
				border-top: 3px solid #f13c36;
				display: flex;
				width: 100%;
		
				.ed_ab_advantageLeft {
					// width: 400px;
					border-right: solid 1px #e2e2e2;
					background-color: #f4f5f7;
					padding: 22px 38px 22px 38px;
					box-sizing: border-box;
		
					.ed_ab_al_title {
						font-size: 24px;
						color: #333333;
						margin-bottom: 36px;
					}
		
					.ed_ab_al_textDiv {
						position: relative;
						padding-left: 46px;
						margin-bottom: 28px;
		
						.ed_ab_al_td_img {
							position: absolute;
							left: 0;
							top: 6px;
							z-index: 1;
						}
		
						.ed_ab_al_td_title {
							font-size: 16px;
							color: #333333;
						}
		
						.ed_ab_al_td_text {
							margin-top: 2px;
							font-size: 14px;
							color: #999999;
						}
					}
				}
		
				.ed_ab_advantageRight {
					padding: 20px 30px;
					box-sizing: border-box;
		
					.ed_ab_ar_title {
						font-size: 24px;
						color: #333333;
					}
		
					.ed_ab_ar_product {
						margin-left: 0;
						background: url(../../../../assets/img/adv-product1.png) #515163;
						display: block;
						width: 220px;
						height: 340px;
						border-radius: 4px;
						margin-top: 20px;
						color: #fff;
						padding: 35px 30px;
						box-sizing: border-box;
						margin-right: 20px;
						
						.ed_ab_ar_pd_title{
							font-size: 20px;
							font-weight: bold;
						}
						
						.ed_ab_ar_pd_text{
							font-size: 14px;
							font-weight: bold;
						}
					}
					
					.ed_ab_ar_product2 {
						margin-left: 0;
						background: url(../../../../assets/img/adv-product2.png) #515163;
						display: block;
						width: 220px;
						height: 340px;
						border-radius: 4px;
						margin-top: 20px;
						color: #fff;
						padding: 35px 30px;
						box-sizing: border-box;
						margin-right: 20px;
						
						.ed_ab_ar_pd_title{
							font-size: 20px;
							font-weight: bold;
						}
						
						.ed_ab_ar_pd_text{
							font-size: 14px;
							font-weight: bold;
						}
					}
					
					.ed_ab_ar_product3 {
						margin-left: 0;
						background: url(../../../../assets/img/adv-product3.png) #515163;
						display: block;
						width: 220px;
						height: 340px;
						border-radius: 4px;
						margin-top: 20px;
						color: #fff;
						padding: 35px 30px;
						box-sizing: border-box;
						margin-right: 20px;
						
						.ed_ab_ar_pd_title{
							font-size: 20px;
							font-weight: bold;
						}
						
						.ed_ab_ar_pd_text{
							font-size: 14px;
							font-weight: bold;
						}
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:1920px) {
		.electronicDesign {
			padding: 40px 200px;
		
			.ed_titleDiv {
				text-align: center;
		
				.ed_td_title {
					font-size: 34px;
					color: #333333;
					text-align: center;
				}
		
				.ed_td_text {
					font-size: 18px;
					color: #999999;
					text-align: center;
					margin-top: 8px;
				}
			}
		
			.ed_simulationBoxed {
				margin: 50px auto 0;
				display: flex;
		
				.ed_sb_simulation {
					width: 25%;
					text-align: center;
					
					.ed_sb_sl_img{
						border: none;
						vertical-align: middle;
						width: 111px;
						height: 111px;
					}
		
					.ed_sb_sl_title {
						margin-top: 16px;
						font-size: 18px;
						font-weight: bold;
						color: #333333;
					}
		
					.ed_sb_sl_text {
						margin-top: 24px;
						font-size: 16px;
						color: #757575;
					}
				}
			}
		
			.ed_advantageBox {
				margin-top: 50px;
				border: solid 1px #e2e2e2;
				border-top: 3px solid #f13c36;
				display: flex;
				width: 100%;
		
				.ed_ab_advantageLeft {
					// width: 400px;
					border-right: solid 1px #e2e2e2;
					background-color: #f4f5f7;
					padding: 22px 38px 22px 38px;
					box-sizing: border-box;
		
					.ed_ab_al_title {
						font-size: 24px;
						color: #333333;
						margin-bottom: 36px;
					}
		
					.ed_ab_al_textDiv {
						position: relative;
						padding-left: 46px;
						margin-bottom: 28px;
		
						.ed_ab_al_td_img {
							position: absolute;
							left: 0;
							top: 6px;
							z-index: 1;
						}
		
						.ed_ab_al_td_title {
							font-size: 16px;
							color: #333333;
						}
		
						.ed_ab_al_td_text {
							margin-top: 2px;
							font-size: 14px;
							color: #999999;
						}
					}
				}
		
				.ed_ab_advantageRight {
					padding: 20px 30px;
					box-sizing: border-box;
		
					.ed_ab_ar_title {
						font-size: 24px;
						color: #333333;
					}
		
					.ed_ab_ar_product {
						margin-left: 0;
						background: url(../../../../assets/img/adv-product1.png) #515163;
						display: block;
						width: 220px;
						height: 340px;
						border-radius: 4px;
						margin-top: 20px;
						color: #fff;
						padding: 35px 30px;
						box-sizing: border-box;
						margin-right: 20px;
						
						.ed_ab_ar_pd_title{
							font-size: 20px;
							font-weight: bold;
						}
						
						.ed_ab_ar_pd_text{
							font-size: 14px;
							font-weight: bold;
						}
					}
					
					.ed_ab_ar_product2 {
						margin-left: 0;
						background: url(../../../../assets/img/adv-product2.png) #515163;
						display: block;
						width: 220px;
						height: 340px;
						border-radius: 4px;
						margin-top: 20px;
						color: #fff;
						padding: 35px 30px;
						box-sizing: border-box;
						margin-right: 20px;
						
						.ed_ab_ar_pd_title{
							font-size: 20px;
							font-weight: bold;
						}
						
						.ed_ab_ar_pd_text{
							font-size: 14px;
							font-weight: bold;
						}
					}
					
					.ed_ab_ar_product3 {
						margin-left: 0;
						background: url(../../../../assets/img/adv-product3.png) #515163;
						display: block;
						width: 220px;
						height: 340px;
						border-radius: 4px;
						margin-top: 20px;
						color: #fff;
						padding: 35px 30px;
						box-sizing: border-box;
						margin-right: 20px;
						
						.ed_ab_ar_pd_title{
							font-size: 20px;
							font-weight: bold;
						}
						
						.ed_ab_ar_pd_text{
							font-size: 14px;
							font-weight: bold;
						}
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:1919px) and (min-width:1440px) {
		.electronicDesign {
			padding: 40px 140px;
		
			.ed_titleDiv {
				text-align: center;
		
				.ed_td_title {
					font-size: 34px;
					color: #333333;
					text-align: center;
				}
		
				.ed_td_text {
					font-size: 18px;
					color: #999999;
					text-align: center;
					margin-top: 8px;
				}
			}
		
			.ed_simulationBoxed {
				margin: 50px auto 0;
				display: flex;
		
				.ed_sb_simulation {
					width: 25%;
					text-align: center;
					
					.ed_sb_sl_img{
						border: none;
						vertical-align: middle;
						width: 111px;
						height: 111px;
					}
		
					.ed_sb_sl_title {
						margin-top: 16px;
						font-size: 18px;
						font-weight: bold;
						color: #333333;
					}
		
					.ed_sb_sl_text {
						margin-top: 24px;
						font-size: 16px;
						color: #757575;
					}
				}
			}
		
			.ed_advantageBox {
				margin-top: 50px;
				border: solid 1px #e2e2e2;
				border-top: 3px solid #f13c36;
				display: flex;
				width: 100%;
		
				.ed_ab_advantageLeft {
					// width: 400px;
					border-right: solid 1px #e2e2e2;
					background-color: #f4f5f7;
					padding: 22px 38px 22px 38px;
					box-sizing: border-box;
		
					.ed_ab_al_title {
						font-size: 24px;
						color: #333333;
						margin-bottom: 36px;
					}
		
					.ed_ab_al_textDiv {
						position: relative;
						padding-left: 46px;
						margin-bottom: 28px;
		
						.ed_ab_al_td_img {
							position: absolute;
							left: 0;
							top: 6px;
							z-index: 1;
						}
		
						.ed_ab_al_td_title {
							font-size: 16px;
							color: #333333;
						}
		
						.ed_ab_al_td_text {
							margin-top: 2px;
							font-size: 14px;
							color: #999999;
						}
					}
				}
		
				.ed_ab_advantageRight {
					padding: 20px 30px;
					box-sizing: border-box;
		
					.ed_ab_ar_title {
						font-size: 24px;
						color: #333333;
					}
		
					.ed_ab_ar_product {
						margin-left: 0;
						background: url(../../../../assets/img/adv-product1.png) #515163;
						display: block;
						width: 220px;
						height: 340px;
						border-radius: 4px;
						margin-top: 20px;
						color: #fff;
						padding: 35px 30px;
						box-sizing: border-box;
						margin-right: 20px;
						
						.ed_ab_ar_pd_title{
							font-size: 20px;
							font-weight: bold;
						}
						
						.ed_ab_ar_pd_text{
							font-size: 14px;
							font-weight: bold;
						}
					}
					
					.ed_ab_ar_product2 {
						margin-left: 0;
						background: url(../../../../assets/img/adv-product2.png) #515163;
						display: block;
						width: 220px;
						height: 340px;
						border-radius: 4px;
						margin-top: 20px;
						color: #fff;
						padding: 35px 30px;
						box-sizing: border-box;
						margin-right: 20px;
						
						.ed_ab_ar_pd_title{
							font-size: 20px;
							font-weight: bold;
						}
						
						.ed_ab_ar_pd_text{
							font-size: 14px;
							font-weight: bold;
						}
					}
					
					.ed_ab_ar_product3 {
						margin-left: 0;
						background: url(../../../../assets/img/adv-product3.png) #515163;
						display: block;
						width: 220px;
						height: 340px;
						border-radius: 4px;
						margin-top: 20px;
						color: #fff;
						padding: 35px 30px;
						box-sizing: border-box;
						margin-right: 20px;
						
						.ed_ab_ar_pd_title{
							font-size: 20px;
							font-weight: bold;
						}
						
						.ed_ab_ar_pd_text{
							font-size: 14px;
							font-weight: bold;
						}
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:1439px) and (min-width:1200px) {
		.electronicDesign {
			padding: 40px 30px;
		
			.ed_titleDiv {
				text-align: center;
		
				.ed_td_title {
					font-size: 34px;
					color: #333333;
					text-align: center;
				}
		
				.ed_td_text {
					font-size: 18px;
					color: #999999;
					text-align: center;
					margin-top: 8px;
				}
			}
		
			.ed_simulationBoxed {
				margin: 50px auto 0;
				display: flex;
		
				.ed_sb_simulation {
					width: 25%;
					text-align: center;
					
					.ed_sb_sl_img{
						border: none;
						vertical-align: middle;
						width: 111px;
						height: 111px;
					}
		
					.ed_sb_sl_title {
						margin-top: 16px;
						font-size: 18px;
						font-weight: bold;
						color: #333333;
					}
		
					.ed_sb_sl_text {
						margin-top: 24px;
						font-size: 16px;
						color: #757575;
					}
				}
			}
		
			.ed_advantageBox {
				margin-top: 50px;
				border: solid 1px #e2e2e2;
				border-top: 3px solid #f13c36;
				display: flex;
				width: 100%;
		
				.ed_ab_advantageLeft {
					// width: 400px;
					border-right: solid 1px #e2e2e2;
					background-color: #f4f5f7;
					padding: 22px 38px 22px 38px;
					box-sizing: border-box;
		
					.ed_ab_al_title {
						font-size: 24px;
						color: #333333;
						margin-bottom: 36px;
					}
		
					.ed_ab_al_textDiv {
						position: relative;
						padding-left: 46px;
						margin-bottom: 28px;
		
						.ed_ab_al_td_img {
							position: absolute;
							left: 0;
							top: 6px;
							z-index: 1;
						}
		
						.ed_ab_al_td_title {
							font-size: 16px;
							color: #333333;
						}
		
						.ed_ab_al_td_text {
							margin-top: 2px;
							font-size: 14px;
							color: #999999;
						}
					}
				}
		
				.ed_ab_advantageRight {
					padding: 20px 30px;
					box-sizing: border-box;
		
					.ed_ab_ar_title {
						font-size: 24px;
						color: #333333;
					}
		
					.ed_ab_ar_product {
						margin-left: 0;
						background: url(../../../../assets/img/adv-product1.png) #515163;
						display: block;
						width: 220px;
						height: 340px;
						border-radius: 4px;
						margin-top: 20px;
						color: #fff;
						padding: 35px 30px;
						box-sizing: border-box;
						margin-right: 20px;
						
						.ed_ab_ar_pd_title{
							font-size: 20px;
							font-weight: bold;
						}
						
						.ed_ab_ar_pd_text{
							font-size: 14px;
							font-weight: bold;
						}
					}
					
					.ed_ab_ar_product2 {
						margin-left: 0;
						background: url(../../../../assets/img/adv-product2.png) #515163;
						display: block;
						width: 220px;
						height: 340px;
						border-radius: 4px;
						margin-top: 20px;
						color: #fff;
						padding: 35px 30px;
						box-sizing: border-box;
						margin-right: 20px;
						
						.ed_ab_ar_pd_title{
							font-size: 20px;
							font-weight: bold;
						}
						
						.ed_ab_ar_pd_text{
							font-size: 14px;
							font-weight: bold;
						}
					}
					
					.ed_ab_ar_product3 {
						margin-left: 0;
						background: url(../../../../assets/img/adv-product3.png) #515163;
						display: block;
						width: 220px;
						height: 340px;
						border-radius: 4px;
						margin-top: 20px;
						color: #fff;
						padding: 35px 30px;
						box-sizing: border-box;
						margin-right: 20px;
						
						.ed_ab_ar_pd_title{
							font-size: 20px;
							font-weight: bold;
						}
						
						.ed_ab_ar_pd_text{
							font-size: 14px;
							font-weight: bold;
						}
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:1199px) and (min-width:768px) {
		.electronicDesign2 {
			padding: 40px 30px;
		
			.ed_titleDiv {
				text-align: center;
		
				.ed_td_title {
					font-size: 21px;
					color: #333333;
					text-align: center;
				}
		
				.ed_td_text {
					font-size: 16px;
					color: #999999;
					text-align: center;
					margin-top: 8px;
				}
			}
		
			.ed_simulationBoxed {
				margin: 50px auto 0;
				display: flex;
				flex-wrap: wrap;
		
				.ed_sb_simulation {
					margin-bottom: 20px;
					width: 48%;
					text-align: center;
					
					.ed_sb_sl_img{
						border: none;
						vertical-align: middle;
						width: 50px;
						height: 50px;
					}
		
					.ed_sb_sl_title {
						margin-top: 16px;
						font-size: 16px;
						font-weight: bold;
						color: #333333;
					}
		
					.ed_sb_sl_text {
						margin-top: 12px;
						font-size: 12px;
						color: #757575;
					}
				}
			}
		
			.ed_advantageBox {
				margin-top: 50px;
				border: solid 1px #e2e2e2;
				border-top: 3px solid #f13c36;
				width: 100%;
		
				.ed_ab_advantageLeft {
					// width: 400px;
					border-right: solid 1px #e2e2e2;
					background-color: #f4f5f7;
					padding: 22px 38px 22px 38px;
					box-sizing: border-box;
		
					.ed_ab_al_title {
						font-size: 21px;
						color: #333333;
						margin-bottom: 36px;
						text-align: center;
					}
		
					.ed_ab_al_textDiv {
						position: relative;
						padding-left: 46px;
						margin-bottom: 28px;
		
						.ed_ab_al_td_img {
							position: absolute;
							left: 0;
							top: 6px;
							z-index: 1;
						}
		
						.ed_ab_al_td_title {
							font-size: 16px;
							color: #333333;
						}
		
						.ed_ab_al_td_text {
							margin-top: 2px;
							font-size: 14px;
							color: #999999;
						}
					}
				}
		
				.ed_ab_advantageRight {
					padding: 20px 30px;
					box-sizing: border-box;
		
					.ed_ab_ar_title {
						font-size: 24px;
						color: #333333;
					}
		
					.ed_ab_ar_product {
						margin-left: 0;
						background: url(../../../../assets/img/adv-product1.png) #515163;
						display: block;
						width: 220px;
						height: 340px;
						border-radius: 4px;
						margin-top: 20px;
						color: #fff;
						padding: 35px 30px;
						box-sizing: border-box;
						margin-right: 20px;
						
						.ed_ab_ar_pd_title{
							font-size: 20px;
							font-weight: bold;
						}
						
						.ed_ab_ar_pd_text{
							font-size: 14px;
							font-weight: bold;
						}
					}
					
					.ed_ab_ar_product2 {
						margin-left: 0;
						background: url(../../../../assets/img/adv-product2.png) #515163;
						display: block;
						width: 220px;
						height: 340px;
						border-radius: 4px;
						margin-top: 20px;
						color: #fff;
						padding: 35px 30px;
						box-sizing: border-box;
						margin-right: 20px;
						
						.ed_ab_ar_pd_title{
							font-size: 20px;
							font-weight: bold;
						}
						
						.ed_ab_ar_pd_text{
							font-size: 14px;
							font-weight: bold;
						}
					}
					
					.ed_ab_ar_product3 {
						margin-left: 0;
						background: url(../../../../assets/img/adv-product3.png) #515163;
						display: block;
						width: 220px;
						height: 340px;
						border-radius: 4px;
						margin-top: 20px;
						color: #fff;
						padding: 35px 30px;
						box-sizing: border-box;
						margin-right: 20px;
						
						.ed_ab_ar_pd_title{
							font-size: 20px;
							font-weight: bold;
						}
						
						.ed_ab_ar_pd_text{
							font-size: 14px;
							font-weight: bold;
						}
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:767px) {
		.electronicDesign2 {
			padding: 40px 30px;
		
			.ed_titleDiv {
				text-align: center;
		
				.ed_td_title {
					font-size: 21px;
					color: #333333;
					text-align: center;
				}
		
				.ed_td_text {
					font-size: 16px;
					color: #999999;
					text-align: center;
					margin-top: 8px;
				}
			}
		
			.ed_simulationBoxed {
				margin: 50px auto 0;
				display: flex;
				flex-wrap: wrap;
		
				.ed_sb_simulation {
					margin-bottom: 20px;
					width: 48%;
					text-align: center;
					
					.ed_sb_sl_img{
						border: none;
						vertical-align: middle;
						width: 50px;
						height: 50px;
					}
		
					.ed_sb_sl_title {
						margin-top: 16px;
						font-size: 16px;
						font-weight: bold;
						color: #333333;
					}
		
					.ed_sb_sl_text {
						margin-top: 12px;
						font-size: 12px;
						color: #757575;
					}
				}
			}
		
			.ed_advantageBox {
				margin-top: 50px;
				border: solid 1px #e2e2e2;
				border-top: 3px solid #f13c36;
				width: 100%;
		
				.ed_ab_advantageLeft {
					// width: 400px;
					border-right: solid 1px #e2e2e2;
					background-color: #f4f5f7;
					padding: 22px 38px 22px 38px;
					box-sizing: border-box;
		
					.ed_ab_al_title {
						font-size: 21px;
						color: #333333;
						margin-bottom: 36px;
						text-align: center;
					}
		
					.ed_ab_al_textDiv {
						position: relative;
						padding-left: 46px;
						margin-bottom: 28px;
		
						.ed_ab_al_td_img {
							position: absolute;
							left: 0;
							top: 6px;
							z-index: 1;
						}
		
						.ed_ab_al_td_title {
							font-size: 16px;
							color: #333333;
						}
		
						.ed_ab_al_td_text {
							margin-top: 2px;
							font-size: 14px;
							color: #999999;
						}
					}
				}
		
				.ed_ab_advantageRight {
					padding: 20px 30px;
					box-sizing: border-box;
		
					.ed_ab_ar_title {
						font-size: 24px;
						color: #333333;
					}
		
					.ed_ab_ar_product {
						margin-left: 0;
						background: url(../../../../assets/img/adv-product1.png) #515163;
						display: block;
						width: 220px;
						height: 340px;
						border-radius: 4px;
						margin-top: 20px;
						color: #fff;
						padding: 35px 30px;
						box-sizing: border-box;
						margin-right: 20px;
						
						.ed_ab_ar_pd_title{
							font-size: 20px;
							font-weight: bold;
						}
						
						.ed_ab_ar_pd_text{
							font-size: 14px;
							font-weight: bold;
						}
					}
					
					.ed_ab_ar_product2 {
						margin-left: 0;
						background: url(../../../../assets/img/adv-product2.png) #515163;
						display: block;
						width: 220px;
						height: 340px;
						border-radius: 4px;
						margin-top: 20px;
						color: #fff;
						padding: 35px 30px;
						box-sizing: border-box;
						margin-right: 20px;
						
						.ed_ab_ar_pd_title{
							font-size: 20px;
							font-weight: bold;
						}
						
						.ed_ab_ar_pd_text{
							font-size: 14px;
							font-weight: bold;
						}
					}
					
					.ed_ab_ar_product3 {
						margin-left: 0;
						background: url(../../../../assets/img/adv-product3.png) #515163;
						display: block;
						width: 220px;
						height: 340px;
						border-radius: 4px;
						margin-top: 20px;
						color: #fff;
						padding: 35px 30px;
						box-sizing: border-box;
						margin-right: 20px;
						
						.ed_ab_ar_pd_title{
							font-size: 20px;
							font-weight: bold;
						}
						
						.ed_ab_ar_pd_text{
							font-size: 14px;
							font-weight: bold;
						}
					}
				}
			}
		}
	}
	
</style>
