<template>
	<div>
		<div class="banner" v-if="windowWidth>=1200">
			<img class="bn_designTitle" src="../../../../assets/img/design-title.png" />
			<img class="bn_operationImg" src="../../../../assets/img/operation-img.png" />
			<!-- <div class="bn_jump">
				<i class="bn_jm_i"></i>
				立即咨询下单
			</div> -->
		</div>
		
		<div class="banner2" v-if="windowWidth<=1199">
			<el-image class="bn_operationImg" :src="imgBanner" :fit="'cover'"></el-image>
			<!-- <div class="bn_jump">
				<i class="bn_jm_i"></i>
				立即咨询下单
			</div> -->
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			//宽
			windowWidth: {
				type: Number,
				default: 0
			},
			//高
			windowHeight: {
				type: Number,
				default: 0
			},
		},
		data() {
			return {
				imgBanner:process.env.VUE_APP_OSS_URL + '/solution/schemeDesignBanner.jpg'
			}
		},

		created() {

		},

		mounted() {

		},

		watch: {

		},

		computed: {

		},

		methods: {

		}
	}
</script>

<style lang="less" scoped>
	
	@media only screen and (min-width:1920px) {
		.banner {
			padding: 40px 200px;
			background: url(../../../../assets/img/design-banner.jpg) no-repeat center #041c26;
			height: 450px;
			overflow: hidden;
		
			.bn_designTitle {
				display: block;
				margin: 160px auto 0;
			}
		
			.bn_operationImg {
				display: block;
				margin: 0 auto;
			}
		
			.bn_jump {
				background: url(../../../../assets/img/banner-btn.png) no-repeat center;
				width: 326px;
				height: 108px;
				display: block;
				margin: 0 auto;
				color: #fff;
				text-align: center;
				font-size: 24px;
				line-height: 84px;
		
				.bn_jm_i {
					display: inline-block;
					vertical-align: middle;
					width: 35px;
					height: 29px;
					margin: 0px 5px 3px 3px;
					background: url(../../../../assets/img/program-banner-qq@icon.png);
				}
			}
		}
	}
	
	@media only screen and (max-width:1920px) {
		.banner {
			padding: 40px 200px;
			background: url(../../../../assets/img/design-banner.jpg) no-repeat center #041c26;
			height: 450px;
			overflow: hidden;
		
			.bn_designTitle {
				display: block;
				margin: 160px auto 0;
			}
		
			.bn_operationImg {
				display: block;
				margin: 0 auto;
			}
		
			.bn_jump {
				background: url(../../../../assets/img/banner-btn.png) no-repeat center;
				width: 326px;
				height: 108px;
				display: block;
				margin: 0 auto;
				color: #fff;
				text-align: center;
				font-size: 24px;
				line-height: 84px;
		
				.bn_jm_i {
					display: inline-block;
					vertical-align: middle;
					width: 35px;
					height: 29px;
					margin: 0px 5px 3px 3px;
					background: url(../../../../assets/img/program-banner-qq@icon.png);
				}
			}
		}
	}
	
	@media only screen and (max-width:1919px) and (min-width:1440px) {
		.banner {
			padding: 40px 140px;
			background: url(../../../../assets/img/design-banner.jpg) no-repeat center #041c26;
			height: 450px;
			overflow: hidden;
		
			.bn_designTitle {
				display: block;
				margin: 160px auto 0;
			}
		
			.bn_operationImg {
				display: block;
				margin: 0 auto;
			}
		
			.bn_jump {
				background: url(../../../../assets/img/banner-btn.png) no-repeat center;
				width: 326px;
				height: 108px;
				display: block;
				margin: 0 auto;
				color: #fff;
				text-align: center;
				font-size: 24px;
				line-height: 84px;
		
				.bn_jm_i {
					display: inline-block;
					vertical-align: middle;
					width: 35px;
					height: 29px;
					margin: 0px 5px 3px 3px;
					background: url(../../../../assets/img/program-banner-qq@icon.png);
				}
			}
		}
	}
	
	@media only screen and (max-width:1439px) and (min-width:1200px) {
		.banner {
			padding: 40px 30px;
			background: url(../../../../assets/img/design-banner.jpg) no-repeat center #041c26;
			height: 450px;
			overflow: hidden;
		
			.bn_designTitle {
				display: block;
				margin: 160px auto 0;
			}
		
			.bn_operationImg {
				display: block;
				margin: 0 auto;
			}
		
			.bn_jump {
				background: url(../../../../assets/img/banner-btn.png) no-repeat center;
				width: 326px;
				height: 108px;
				display: block;
				margin: 0 auto;
				color: #fff;
				text-align: center;
				font-size: 24px;
				line-height: 84px;
		
				.bn_jm_i {
					display: inline-block;
					vertical-align: middle;
					width: 35px;
					height: 29px;
					margin: 0px 5px 3px 3px;
					background: url(../../../../assets/img/program-banner-qq@icon.png);
				}
			}
		}
	}
	
	@media only screen and (max-width:1199px) and (min-width:768px) {
		.banner2 {
			// padding: 100px 30px 40px 30px;
			margin-top: 70px;
		
			.bn_designTitle {
				display: block;
				margin: 160px auto 0;
			}
		
			.bn_operationImg {
				display: block;
				margin: 0 auto;
			}
		
			.bn_jump {
				background: url(../../../../assets/img/banner-btn.png) no-repeat center;
				width: 326px;
				height: 108px;
				display: block;
				margin: 0 auto;
				color: #fff;
				text-align: center;
				font-size: 24px;
				line-height: 84px;
		
				.bn_jm_i {
					display: inline-block;
					vertical-align: middle;
					width: 35px;
					height: 29px;
					margin: 0px 5px 3px 3px;
					background: url(../../../../assets/img/program-banner-qq@icon.png);
				}
			}
		}
	}
	
	@media only screen and (max-width:767px) {
		.banner2 {
			// padding: 100px 30px 40px 30px;
			margin-top: 70px;
		
			.bn_designTitle {
				display: block;
				margin: 160px auto 0;
			}
		
			.bn_operationImg {
				display: block;
				margin: 0 auto;
			}
		
			.bn_jump {
				background: url(../../../../assets/img/banner-btn.png) no-repeat center;
				width: 326px;
				height: 108px;
				display: block;
				margin: 0 auto;
				color: #fff;
				text-align: center;
				font-size: 24px;
				line-height: 84px;
		
				.bn_jm_i {
					display: inline-block;
					vertical-align: middle;
					width: 35px;
					height: 29px;
					margin: 0px 5px 3px 3px;
					background: url(../../../../assets/img/program-banner-qq@icon.png);
				}
			}
		}
	}
	
</style>
