<template>
	<div>
		<div v-if="windowWidth>=1200">
			<div class="serviceArea">
				<div class="sa_title">{{$t('solution.schemeDesign.serviceArea.title')}}</div>

				<div class="sa_tabsListDiv">
					<div class="sa_tld_tabsList"
						v-for="(item,index) in $t('solution.schemeDesign.serviceArea.tabsList')" :key="index"
						:class="tabsIndex == index ? 'active' : ''" @click="tabsClick(index)">
						<div style="width: 100%;display: flex;justify-content: center;">
							<div class="sa_tld_tl_img">
								<img style="border: none;vertical-align: middle;" :src="item.img" />
							</div>
						</div>
						<div class="sa_tld_tl_name" :style="tabsIndex == index ? 'color:#f13c36;' : ''">{{item.name}}</div>
					</div>
				</div>
			</div>

			<div class="fieldRow">
				<div style="display: flex;flex-wrap: wrap;">
					<div class="fr_div" v-for="(item,index) in $t('solution.schemeDesign.serviceArea.dataList')"
						:key="index" v-if="tabsIndex==item.id">
						<div class="fr_di_img">
							<el-image style="height: 100%;width: 100%;" :src="item.img" :fit="'fill'"></el-image>
							<div class="fr_di_img_hiddenEl">
								<div class="fr_di_img_he_hiddenText">
									{{item.content}}
								</div>

								<div class="fr_di_img_he_ht_jump">
									{{$t('solution.schemeDesign.serviceArea.button2')}}
								</div>
							</div>
						</div>

						<div class="fr_di_title">{{item.title}}</div>
					</div>

				</div>

				<div class="jump-link">
					{{$t('solution.schemeDesign.serviceArea.button')}}
				</div>
			</div>
		</div>

		<div v-if="windowWidth<=1199">
			<div class="serviceArea2">
				<div class="sa_title">{{$t('solution.schemeDesign.serviceArea.title')}}</div>

				<div class="navbar-list">
					<ul class="flex-imd">
						<li v-for="(item,index) in $t('solution.schemeDesign.serviceArea.tabsList')" :key="index" style="position: relative;">
							<div style="width: 100%;display: flex;justify-content: center;">
								<div style="width: 80px;height: 56px;display: table-cell;vertical-align: middle;text-align: center;">
									<img style="border: none;vertical-align: middle;" :src="item.img" />
								</div>
							</div>
							<div class="navbar-item" :class="currNavbar===index?'act':''" @click="chooseNav" :data-navid='index'>{{item.name}}</div>
						</li>
					</ul>
				</div>
			</div>

			<div class="fieldRow">
				<div style="display: flex;flex-wrap: wrap;">
					<div class="fr_div" v-for="(item,index) in $t('solution.schemeDesign.serviceArea.dataList')"
						:key="index" v-if="currNavbar==item.id">
						<div class="fr_di_img">
							<el-image style="height: 100%;width: 100%;" :src="item.img" :fit="'fill'"></el-image>
							<div class="fr_di_img_hiddenEl">
								<div class="fr_di_img_he_hiddenText">
									{{item.content}}
								</div>

								<div class="fr_di_img_he_ht_jump">
									{{$t('solution.schemeDesign.serviceArea.button2')}}
								</div>
							</div>
						</div>

						<div class="fr_di_title">{{item.title}}</div>
					</div>

				</div>

				<div class="jump-link">
					{{$t('solution.schemeDesign.serviceArea.button')}}
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			//宽
			windowWidth: {
				type: Number,
				default: 0
			},
			//高
			windowHeight: {
				type: Number,
				default: 0
			},
		},
		data() {
			return {
				tabsIndex: 0, //tabs下标
				currNavbar:0,
			}
		},

		created() {

		},

		mounted() {

		},

		watch: {

		},

		computed: {

		},

		methods: {
			//--------------------tabs点击事件---------------------------------
			tabsClick: function(index) {
				this.tabsIndex = index;
			},
			
			chooseNav (e) {
			      this.currNavbar = Number(e.target.dataset.navid)
			       	// 当前点击元素的左边距离
			        const distanceL = document.getElementsByClassName('navbar-item act')[0].getBoundingClientRect().left
			         // 点击元素的一半宽度
			        const elementHW = document.getElementsByClassName('navbar-item act')[0].clientWidth / 2
			        // 屏幕宽度
			        const screenW = document.body.clientWidth
			        // 屏幕宽度的一半
			        const screenHW = document.body.clientWidth / 2
			        // 元素右边距离
			        const distanceR = screenW - distanceL
			        // 获取最外层的元素
			        const scrollBox = document.getElementsByClassName('navbar-list')
			        // 滚动条滚动的距离
			        const scrollL = scrollBox[0].scrollLeft
			        // 当元素左边距离大于屏幕一半宽度  或者  右边距离大于屏幕一半距离的时候
			        if (distanceL > (screenHW - elementHW) || distanceR > (screenHW - elementHW)) {
			           // 滚动条最终的滚动距离
			          scrollBox[0].scrollLeft = scrollL + (distanceL - screenHW + elementHW)
			        }
			}
		}
	}
</script>

<style lang="less" scoped>
	@media only screen and (min-width:1920px) {
		.serviceArea {
			padding: 40px 200px 0 200px;

			.sa_title {
				font-size: 34px;
				color: #333333;
				text-align: center;
				margin-top: 30px;
			}

			.sa_tabsListDiv {
				text-align: center;
				margin-top: 45px;
				cursor: pointer;

				.sa_tld_tabsList {
					margin-top: 20px;
					display: inline-block;
					vertical-align: top;
					padding: 0 65px;
					padding-bottom: 27px;
					position: relative;
					cursor: pointer;

					.sa_tld_tl_img {
						width: 80px;
						height: 56px;
						display: table-cell;
						vertical-align: middle;
						text-align: center;
					}

					.sa_tld_tl_name {
						font-size: 16px;
						text-align: center;
						color: #333333;
					}
				}
			}
		}

		.fieldRow {
			background: #f4f5f7;
			padding: 60px 200px 44px 200px;

			.fr_div {
				margin: 0 0 24px 8px;
				width: 24%;

				.fr_di_img {
					width: 100%;
					height: 189px;
					background-color: #565656;
					position: relative;
					overflow: hidden;

					.fr_di_img_hiddenEl {
						position: absolute;
						height: 100%;
						width: 100%;
						padding: 32px;
						box-sizing: border-box;
						font-size: 14px;
						color: #fff;
						bottom: -100%;
						left: 0;
						z-index: 3;
						transition: all 0.3s linear;

						.fr_di_img_he_hiddenText {
							width: 250px;
							height: 80px;
							line-height: 26px;
							overflow: hidden;
							text-overflow: ellipsis;
							display: -webkit-box;
							-webkit-line-clamp: 3;
							-webkit-box-orient: vertical;
						}

						.fr_di_img_he_ht_jump {
							display: block;
							width: 95px;
							height: 34px;
							line-height: 34px;
							text-align: center;
							font-size: 14px;
							border-radius: 3px;
							border: solid 1px #f13c36;
							color: #f13c36;
							margin-top: 8px;
							cursor: pointer;
						}
					}
				}

				.fr_di_img::before {
					display: block;
					content: '';
					width: 100%;
					height: 100%;
					background: #222;
					position: absolute;
					top: 0;
					left: 0;
					z-index: 0;
					opacity: 0;
					transition: all 0.3s ease;
				}
			}

			.fr_di_title {
				height: 46px;
				line-height: 46px;
				background: #fff;
				text-overflow: ellipsis;
				overflow: hidden;
				white-space: nowrap;
				padding: 0 10px;
				box-sizing: border-box;
				font-size: 16px;
				color: #333333;
				width: 100%;
				text-align: center;
				transition: all 0.16s ease;
			}
		}

		.jump-link {
			background: url(../../../../assets/img/more-link.png) no-repeat center;
			display: block;
			width: 324px;
			height: 106px;
			margin: 30px auto 0;
			color: #fff;
			font-size: 26px;
			line-height: 80px;
			text-align: center;
			cursor: pointer;
		}

		.fr_div:hover .fr_di_title {
			background: #f13c36;
			color: #fff;
		}

		.fr_div:hover .fr_di_img::before {
			z-index: 3;
			opacity: 0.5;
		}

		.fr_div:hover .fr_di_img .fr_di_img_hiddenEl {
			bottom: 0;
		}

		.active {}

		.active::after {
			display: block;
			content: '';
			width: 205px;
			border-bottom: 3px solid #f13c36;
			position: absolute;
			left: 0;
			bottom: 0;
			z-index: 3;
		}
	}

	@media only screen and (max-width:1920px) {
		.serviceArea {
			padding: 40px 200px 0 200px;

			.sa_title {
				font-size: 34px;
				color: #333333;
				text-align: center;
				margin-top: 30px;
			}

			.sa_tabsListDiv {
				text-align: center;
				margin-top: 45px;
				cursor: pointer;

				.sa_tld_tabsList {
					margin-top: 20px;
					display: inline-block;
					vertical-align: top;
					padding: 0 65px;
					padding-bottom: 27px;
					position: relative;
					cursor: pointer;

					.sa_tld_tl_img {
						width: 80px;
						height: 56px;
						display: table-cell;
						vertical-align: middle;
						text-align: center;
					}

					.sa_tld_tl_name {
						font-size: 16px;
						text-align: center;
						color: #333333;
					}
				}
			}
		}

		.fieldRow {
			background: #f4f5f7;
			padding: 60px 200px 44px 200px;

			.fr_div {
				margin: 0 0 24px 8px;
				width: 24%;

				.fr_di_img {
					width: 100%;
					height: 189px;
					background-color: #565656;
					position: relative;
					overflow: hidden;

					.fr_di_img_hiddenEl {
						position: absolute;
						height: 100%;
						width: 100%;
						padding: 32px;
						box-sizing: border-box;
						font-size: 14px;
						color: #fff;
						bottom: -100%;
						left: 0;
						z-index: 3;
						transition: all 0.3s linear;

						.fr_di_img_he_hiddenText {
							width: 250px;
							height: 80px;
							line-height: 26px;
							overflow: hidden;
							text-overflow: ellipsis;
							display: -webkit-box;
							-webkit-line-clamp: 3;
							-webkit-box-orient: vertical;
						}

						.fr_di_img_he_ht_jump {
							display: block;
							width: 95px;
							height: 34px;
							line-height: 34px;
							text-align: center;
							font-size: 14px;
							border-radius: 3px;
							border: solid 1px #f13c36;
							color: #f13c36;
							margin-top: 8px;
							cursor: pointer;
						}
					}
				}

				.fr_di_img::before {
					display: block;
					content: '';
					width: 100%;
					height: 100%;
					background: #222;
					position: absolute;
					top: 0;
					left: 0;
					z-index: 0;
					opacity: 0;
					transition: all 0.3s ease;
				}
			}

			.fr_di_title {
				height: 46px;
				line-height: 46px;
				background: #fff;
				text-overflow: ellipsis;
				overflow: hidden;
				white-space: nowrap;
				padding: 0 10px;
				box-sizing: border-box;
				font-size: 16px;
				color: #333333;
				width: 100%;
				text-align: center;
				transition: all 0.16s ease;
			}
		}

		.jump-link {
			background: url(../../../../assets/img/more-link.png) no-repeat center;
			display: block;
			width: 324px;
			height: 106px;
			margin: 30px auto 0;
			color: #fff;
			font-size: 26px;
			line-height: 80px;
			text-align: center;
			cursor: pointer;
		}

		.fr_div:hover .fr_di_title {
			background: #f13c36;
			color: #fff;
		}

		.fr_div:hover .fr_di_img::before {
			z-index: 3;
			opacity: 0.5;
		}

		.fr_div:hover .fr_di_img .fr_di_img_hiddenEl {
			bottom: 0;
		}

		.active {}

		.active::after {
			display: block;
			content: '';
			width: 205px;
			border-bottom: 3px solid #f13c36;
			position: absolute;
			left: 0;
			bottom: 0;
			z-index: 3;
		}
	}

	@media only screen and (max-width:1919px) and (min-width:1440px) {
		.serviceArea {
			padding: 40px 140px 0 140px;

			.sa_title {
				font-size: 34px;
				color: #333333;
				text-align: center;
				margin-top: 30px;
			}

			.sa_tabsListDiv {
				text-align: center;
				margin-top: 45px;
				cursor: pointer;

				.sa_tld_tabsList {
					margin-top: 20px;
					display: inline-block;
					vertical-align: top;
					padding: 0 65px;
					padding-bottom: 27px;
					position: relative;
					cursor: pointer;

					.sa_tld_tl_img {
						width: 80px;
						height: 56px;
						display: table-cell;
						vertical-align: middle;
						text-align: center;
					}

					.sa_tld_tl_name {
						font-size: 16px;
						text-align: center;
						color: #333333;
					}
				}
			}
		}

		.fieldRow {
			background: #f4f5f7;
			padding: 60px 140px 44px 140px;

			.fr_div {
				margin: 0 0 24px 8px;
				width: 24%;

				.fr_di_img {
					width: 100%;
					height: 189px;
					background-color: #565656;
					position: relative;
					overflow: hidden;

					.fr_di_img_hiddenEl {
						position: absolute;
						height: 100%;
						width: 100%;
						padding: 32px;
						box-sizing: border-box;
						font-size: 14px;
						color: #fff;
						bottom: -100%;
						left: 0;
						z-index: 3;
						transition: all 0.3s linear;

						.fr_di_img_he_hiddenText {
							width: 250px;
							height: 80px;
							line-height: 26px;
							overflow: hidden;
							text-overflow: ellipsis;
							display: -webkit-box;
							-webkit-line-clamp: 3;
							-webkit-box-orient: vertical;
						}

						.fr_di_img_he_ht_jump {
							display: block;
							width: 95px;
							height: 34px;
							line-height: 34px;
							text-align: center;
							font-size: 14px;
							border-radius: 3px;
							border: solid 1px #f13c36;
							color: #f13c36;
							margin-top: 8px;
							cursor: pointer;
						}
					}
				}

				.fr_di_img::before {
					display: block;
					content: '';
					width: 100%;
					height: 100%;
					background: #222;
					position: absolute;
					top: 0;
					left: 0;
					z-index: 0;
					opacity: 0;
					transition: all 0.3s ease;
				}
			}

			.fr_di_title {
				height: 46px;
				line-height: 46px;
				background: #fff;
				text-overflow: ellipsis;
				overflow: hidden;
				white-space: nowrap;
				padding: 0 10px;
				box-sizing: border-box;
				font-size: 16px;
				color: #333333;
				width: 100%;
				text-align: center;
				transition: all 0.16s ease;
			}
		}

		.jump-link {
			background: url(../../../../assets/img/more-link.png) no-repeat center;
			display: block;
			width: 324px;
			height: 106px;
			margin: 30px auto 0;
			color: #fff;
			font-size: 26px;
			line-height: 80px;
			text-align: center;
			cursor: pointer;
		}

		.fr_div:hover .fr_di_title {
			background: #f13c36;
			color: #fff;
		}

		.fr_div:hover .fr_di_img::before {
			z-index: 3;
			opacity: 0.5;
		}

		.fr_div:hover .fr_di_img .fr_di_img_hiddenEl {
			bottom: 0;
		}

		.active {}

		.active::after {
			display: block;
			content: '';
			width: 205px;
			border-bottom: 3px solid #f13c36;
			position: absolute;
			left: 0;
			bottom: 0;
			z-index: 3;
		}
	}

	@media only screen and (max-width:1439px) and (min-width:1200px) {
		.serviceArea {
			padding: 40px 30px 0 30px;

			.sa_title {
				font-size: 34px;
				color: #333333;
				text-align: center;
				margin-top: 30px;
			}

			.sa_tabsListDiv {
				text-align: center;
				margin-top: 45px;
				cursor: pointer;

				.sa_tld_tabsList {
					margin-top: 20px;
					display: inline-block;
					vertical-align: top;
					padding: 0 40px;
					padding-bottom: 27px;
					position: relative;
					cursor: pointer;

					.sa_tld_tl_img {
						width: 80px;
						height: 56px;
						display: table-cell;
						vertical-align: middle;
						text-align: center;
					}

					.sa_tld_tl_name {
						font-size: 16px;
						text-align: center;
						color: #333333;
					}
				}
			}
		}

		.fieldRow {
			background: #f4f5f7;
			padding: 60px 30px 44px 30px;

			.fr_div {
				margin: 0 0 24px 8px;
				width: 24%;

				.fr_di_img {
					width: 100%;
					height: 189px;
					background-color: #565656;
					position: relative;
					overflow: hidden;

					.fr_di_img_hiddenEl {
						position: absolute;
						height: 100%;
						width: 100%;
						box-sizing: border-box;
						font-size: 14px;
						color: #fff;
						bottom: -100%;
						left: 0;
						z-index: 3;
						transition: all 0.3s linear;

						.fr_di_img_he_hiddenText {
							width: 250px;
							height: 80px;
							line-height: 26px;
							overflow: hidden;
							text-overflow: ellipsis;
							display: -webkit-box;
							-webkit-line-clamp: 3;
							-webkit-box-orient: vertical;
						}

						.fr_di_img_he_ht_jump {
							display: block;
							width: 95px;
							height: 34px;
							line-height: 34px;
							text-align: center;
							font-size: 14px;
							border-radius: 3px;
							border: solid 1px #f13c36;
							color: #f13c36;
							margin-top: 8px;
							cursor: pointer;
						}
					}
				}

				.fr_di_img::before {
					display: block;
					content: '';
					width: 100%;
					height: 100%;
					background: #222;
					position: absolute;
					top: 0;
					left: 0;
					z-index: 0;
					opacity: 0;
					transition: all 0.3s ease;
				}
			}

			.fr_di_title {
				height: 46px;
				line-height: 46px;
				background: #fff;
				text-overflow: ellipsis;
				overflow: hidden;
				white-space: nowrap;
				padding: 0 10px;
				box-sizing: border-box;
				font-size: 16px;
				color: #333333;
				width: 100%;
				text-align: center;
				transition: all 0.16s ease;
			}
		}

		.jump-link {
			background: url(../../../../assets/img/more-link.png) no-repeat center;
			display: block;
			width: 324px;
			height: 106px;
			margin: 30px auto 0;
			color: #fff;
			font-size: 26px;
			line-height: 80px;
			text-align: center;
			cursor: pointer;
		}

		.fr_div:hover .fr_di_title {
			background: #f13c36;
			color: #fff;
		}

		.fr_div:hover .fr_di_img::before {
			z-index: 3;
			opacity: 0.5;
		}

		.fr_div:hover .fr_di_img .fr_di_img_hiddenEl {
			bottom: 0;
		}

		.active {}

		.active::after {
			display: block;
			content: '';
			width: 100%;
			border-bottom: 3px solid #f13c36;
			position: absolute;
			left: 0;
			bottom: 0;
			z-index: 3;
		}
	}

	@media only screen and (max-width:1199px) and (min-width:768px) {
		.serviceArea2 {
			padding: 20px 30px 0 30px;

			.sa_title {
				font-size: 21px;
				color: #333333;
				text-align: center;
			}

		}

		.fieldRow {
			background: #f4f5f7;
			padding: 60px 30px 44px 30px;

			.fr_div {
				margin: 0 0 24px 8px;
				width: 48%;

				.fr_di_img {
					width: 100%;
					background-color: #565656;
					position: relative;
					overflow: hidden;

					.fr_di_img_hiddenEl {
						position: absolute;
						height: 100%;
						width: 100%;
						box-sizing: border-box;
						font-size: 14px;
						color: #fff;
						bottom: -100%;
						left: 0;
						z-index: 3;
						transition: all 0.3s linear;

						.fr_di_img_he_hiddenText {
							width: 250px;
							height: 80px;
							line-height: 26px;
							overflow: hidden;
							text-overflow: ellipsis;
							display: -webkit-box;
							-webkit-line-clamp: 3;
							-webkit-box-orient: vertical;
						}

						.fr_di_img_he_ht_jump {
							display: block;
							width: 95px;
							height: 34px;
							line-height: 34px;
							text-align: center;
							font-size: 14px;
							border-radius: 3px;
							border: solid 1px #f13c36;
							color: #f13c36;
							margin-top: 8px;
							cursor: pointer;
						}
					}
				}

				.fr_di_img::before {
					display: block;
					content: '';
					width: 100%;
					height: 100%;
					background: #222;
					position: absolute;
					top: 0;
					left: 0;
					z-index: 0;
					opacity: 0;
					transition: all 0.3s ease;
				}
			}

			.fr_di_title {
				height: 46px;
				line-height: 46px;
				background: #fff;
				text-overflow: ellipsis;
				overflow: hidden;
				white-space: nowrap;
				padding: 0 10px;
				box-sizing: border-box;
				font-size: 16px;
				color: #333333;
				width: 100%;
				text-align: center;
				transition: all 0.16s ease;
			}
		}

		.jump-link {
			background: url(../../../../assets/img/more-link.png) no-repeat center;
			display: block;
			width: 324px;
			height: 106px;
			margin: 30px auto 0;
			color: #fff;
			font-size: 26px;
			line-height: 80px;
			text-align: center;
			cursor: pointer;
		}

		.fr_div:hover .fr_di_title {
			background: #f13c36;
			color: #fff;
		}

		.fr_div:hover .fr_di_img::before {
			z-index: 3;
			opacity: 0.5;
		}

		.fr_div:hover .fr_di_img .fr_di_img_hiddenEl {
			bottom: 0;
		}

		.active {}

		.active::after {
			display: block;
			content: '';
			width: 100%;
			border-bottom: 3px solid #f13c36;
			position: absolute;
			left: 0;
			bottom: 0;
			z-index: 3;
		}
		
		.navbar-list{
		  margin: 60px 0 20px 0;
		  width: 100%;
		  height: 80px;
		  position: relative;
		  overflow-x: scroll;
		  overflow-y: hidden;
		}
		.navbar-list>ul{
		  /* padding: 0 20px; */
		  position: absolute;
		  height: 80px;
		  left: 0;
		  display: flex;
		  flex-flow: row nowrap;
		}
		.navbar-item{
		  /* width: 120px; */
		  width: max-content;
		  padding: 0px 18px;
		  box-sizing: border-box;
		  display: flex;
		  flex-flow: column nowrap;
		  justify-content: space-around;
		  align-items: center;
		  cursor: pointer;
		  /* margin-right: 10px; */
		}
		.navbar-list::-webkit-scrollbar {
		  display: none;
		}
		.act{
		  background-image: -webkit-linear-gradient(left, #d1102d, #d1102d);
		  -webkit-background-clip: text;
		  -webkit-text-fill-color: transparent;
		  font-weight: bold;
		}
		li {
			list-style: none;
			padding: 0px;
			margin: 0px;
		}
	}

	@media only screen and (max-width:767px) {
		.serviceArea2 {
			padding: 20px 30px 0 30px;

			.sa_title {
				font-size: 21px;
				color: #333333;
				text-align: center;
			}

		}

		.fieldRow {
			background: #f4f5f7;
			padding: 60px 30px 44px 30px;

			.fr_div {
				margin: 0 0 24px 0;
				width: 100%;

				.fr_di_img {
					width: 100%;
					height: 250px;
					background-color: #565656;
					position: relative;
					overflow: hidden;

					.fr_di_img_hiddenEl {
						position: absolute;
						height: 100%;
						width: 100%;
						box-sizing: border-box;
						font-size: 14px;
						color: #fff;
						bottom: -100%;
						left: 0;
						z-index: 3;
						transition: all 0.3s linear;

						.fr_di_img_he_hiddenText {
							width: 250px;
							height: 80px;
							line-height: 26px;
							overflow: hidden;
							text-overflow: ellipsis;
							display: -webkit-box;
							-webkit-line-clamp: 3;
							-webkit-box-orient: vertical;
						}

						.fr_di_img_he_ht_jump {
							display: block;
							width: 95px;
							height: 34px;
							line-height: 34px;
							text-align: center;
							font-size: 14px;
							border-radius: 3px;
							border: solid 1px #f13c36;
							color: #f13c36;
							margin-top: 8px;
							cursor: pointer;
						}
					}
				}

				.fr_di_img::before {
					display: block;
					content: '';
					width: 100%;
					height: 100%;
					background: #222;
					position: absolute;
					top: 0;
					left: 0;
					z-index: 0;
					opacity: 0;
					transition: all 0.3s ease;
				}
			}

			.fr_di_title {
				height: 46px;
				line-height: 46px;
				background: #fff;
				text-overflow: ellipsis;
				overflow: hidden;
				white-space: nowrap;
				padding: 0 10px;
				box-sizing: border-box;
				font-size: 16px;
				color: #333333;
				width: 100%;
				text-align: center;
				transition: all 0.16s ease;
			}
		}

		.jump-link {
			background: url(../../../../assets/img/more-link.png) no-repeat center;
			display: block;
			width: 324px;
			height: 106px;
			margin: 30px auto 0;
			color: #fff;
			font-size: 26px;
			line-height: 80px;
			text-align: center;
			cursor: pointer;
		}

		.fr_div:hover .fr_di_title {
			background: #f13c36;
			color: #fff;
		}

		.fr_div:hover .fr_di_img::before {
			z-index: 3;
			opacity: 0.5;
		}

		.fr_div:hover .fr_di_img .fr_di_img_hiddenEl {
			bottom: 0;
		}

		.active {}

		.active::after {
			display: block;
			content: '';
			width: 100%;
			border-bottom: 3px solid #f13c36;
			position: absolute;
			left: 0;
			bottom: 0;
			z-index: 3;
		}
		
		.navbar-list{
		  margin: 60px 0 20px 0;
		  width: 100%;
		  height: 80px;
		  position: relative;
		  overflow-x: scroll;
		  overflow-y: hidden;
		}
		.navbar-list>ul{
		  /* padding: 0 20px; */
		  position: absolute;
		  height: 80px;
		  left: 0;
		  display: flex;
		  flex-flow: row nowrap;
		}
		.navbar-item{
		  /* width: 120px; */
		  width: max-content;
		  padding: 0px 18px;
		  box-sizing: border-box;
		  display: flex;
		  flex-flow: column nowrap;
		  justify-content: space-around;
		  align-items: center;
		  cursor: pointer;
		  /* margin-right: 10px; */
		}
		.navbar-list::-webkit-scrollbar {
		  display: none;
		}
		.act{
		  background-image: -webkit-linear-gradient(left, #d1102d, #d1102d);
		  -webkit-background-clip: text;
		  -webkit-text-fill-color: transparent;
		  font-weight: bold;
		}
		li {
			list-style: none;
			padding: 0px;
			margin: 0px;
		}
	}
</style>